// src/components/ApplicationList.js
import React, { useState, useEffect } from 'react';
import { Grid, Container, Box, Typography, List, ListItem, ListItemText, TextField, Button, Checkbox, FormControlLabel } from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import ApplicationCard from './ApplicationCard';

const apiToken = "e87846577d61104a5ed9647b8653f63a70dbd1ca05c5813ee8d484ec35db9ea48d1dc84d82e2ddfce33da81ddaded076cfec872e298a47c75bf57ce14379b5d65e5c1cc2b3b2af28f4e3a08cefd55bc1cf2f2c2009bfc88a2765adb0fbac82a311ab8edb82a716b3984f1f5a9018342971d5b589e3447f32f512d3e42b94724d"

const HighlightedListItem = styled(ListItem)(({ theme }) => ({
    color: theme.palette.primary.main,
}));

const ApplicationList = () => {
    const [applications, setApplications] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(1);

    const [initialPageSize] = useState(50); // Number of items to fetch per page initially
    const [initialPagesToLoad] = useState(10); // Number of pages to load initially
    const [initialPageLimit] = useState(10); // Number of pages to fetch when loading more

    const [loadMorePageSize] = useState(50); // Number of items to fetch per page when loading more
    const [loadMorePageLimit] = useState(10); // Number of pages to fetch when loading more

    const [loading, setLoading] = useState(false);
    const [featured, setFeatured] = useState(true); // New state for the Featured checkbox
    const [dockercompose, setDockercompose] = useState(true); // New state for the Featured checkbox

    const [totalItems, setTotalItems] = useState(0); // State for total items fetched
    const [endOfList, setEndOfList] = useState(false); // State to indicate end of list

    useEffect(() => {
        const fetchInitialApplications = async () => {
            setLoading(true);
            let allApplications = [];
            for (let i = 1; i <= initialPagesToLoad; i++) {
                const res = await axios.get(`https://cms.mlops.io/api/catalogs?sort[0]=catalogId&pagination[page]=${i}&pagination[pageSize]=${initialPageSize}&populate=icon,images`, { headers: { 'Authorization': 'Bearer: ' + apiToken } });
                allApplications = [...allApplications, ...res.data.data];
            }
            setApplications(allApplications);
            setTotalItems(allApplications.length);
            setPage(initialPagesToLoad);
            setLoading(false);
        };

        const fetchCategories = async () => {
            const res = await axios.get(`https://cms.mlops.io/api/categories?sort[0]=name&pagination[pageSize]=${initialPageSize}`, { headers: { 'Authorization': 'Bearer: ' + apiToken } });
            setCategories(res.data.data);
        };

        fetchInitialApplications();
        fetchCategories();
    }, [initialPagesToLoad, initialPageLimit]);

    /**
    useEffect(() => {
        const fetchApplications = async () => {
            setLoading(true);
            const res = await axios.get(`https://cms.mlops.io/api/catalogs?sort[0]=catalogId&pagination[page]=${page}&pagination[pageSize]=${limit}&populate=icon,images`, { headers: { 'Authorization': 'Bearer: ' + apiToken } });
            // const res = await axios.get(`https://cms.mlops.io/api/catalogs?sort[0]=catalogId&pagination[page]=${page}&pagination[limit]=${limit}&populate=icon,images`, { headers: { 'Authorization': 'Bearer: ' + apiToken } });
            setApplications((prevApplications) => [...prevApplications, ...res.data.data]);
            setLoading(false);
        };

        const fetchCategories = async () => {
            const res = await axios.get(`https://cms.mlops.io/api/categories?sort[0]=name&pagination[limit]=${limit}`, { headers: { 'Authorization': 'Bearer: ' + apiToken } });
            setCategories(res.data.data);
        };

        fetchApplications();
        fetchCategories();
    }, [page]);
  **/

    const handleCategoryClick = (categoryId) => {
        const category = categories.find(category => category.id === categoryId);
        setSelectedCategory(null);
        if (category !== undefined) {
            setSelectedCategory(category.attributes.categoryId);
        }
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleFeaturedChange = (event) => {
        setFeatured(event.target.checked);
    };

    const handleDockercomposeChange = (event) => {
        setDockercompose(event.target.checked);
    };

    const loadMoreApplications = async () => {
        setLoading(true);
        let newApplications = [];
        const nextPage = page + 1;
        const endPage = nextPage + loadMorePageLimit - 1;
        let totalNewItems = 0;

        for (let i = nextPage; i <= endPage; i++) {
            const res = await axios.get(`https://cms.mlops.io/api/catalogs?sort[0]=catalogId&pagination[page]=${i}&pagination[pageSize]=${loadMorePageSize}&populate=icon,images`, { headers: { 'Authorization': 'Bearer: ' + apiToken } });
            const fetchedApplications = res.data.data;
            totalNewItems += fetchedApplications.length;
            newApplications = [...newApplications, ...fetchedApplications];
            if (fetchedApplications.length < loadMorePageSize) {
                setEndOfList(true);
                break;
            }
        }

        setApplications((prevApplications) => [...prevApplications, ...newApplications]);
        setTotalItems((prevTotal) => prevTotal + totalNewItems);
        setPage(nextPage + loadMorePageLimit - 1);
        setLoading(false);
    };

    /**
    const loadMoreApplications = async () => {
        setLoading(true);
        const res = await axios.get(`https://cms.mlops.io/api/catalogs?sort[0]=catalogId&pagination[page]=${page + 1}&pagination[pageSize]=${loadMorePageLimit}&populate=icon,images`, { headers: { 'Authorization': 'Bearer: ' + apiToken } });
        setApplications((prevApplications) => [...prevApplications, ...res.data.data]);
        setPage((prevPage) => prevPage + 1);
        setLoading(false);
    };
    **/

    /**
    const loadMoreApplications = async () => {
        setLoading(true);
        const res = await axios.get(`https://cms.mlops.io/api/catalogs?sort[0]=catalogId&pagination[page]=${page + 1}&pagination[pageSize]=${limit}&populate=icon,images`, { headers: { 'Authorization': 'Bearer: ' + apiToken } });
        setApplications((prevApplications) => [...prevApplications, ...res.data.data]);
        setPage((prevPage) => prevPage + 1);
        setLoading(false);
    };
    **/

    //const loadMoreApplications = () => {
    //   setPage((prevPage) => prevPage + 1);
    //};

    const filteredApplications = applications
        .filter((app) =>
            app.attributes.name.toLowerCase().includes(searchTerm.toLowerCase())
        )
        .filter((app) =>
            selectedCategory ? app.attributes.categoryId === selectedCategory : true
        )
        .filter((app) =>
            featured ? app.attributes.tags !== null && app.attributes.tags.includes("featured") === true : true
        )
        .filter((app) =>
            dockercompose ? app.attributes.tags !== null && app.attributes.tags.includes("dockercompose") === true : true
        );


    return (
        <Container style={{ marginTop: '40px' }}>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <Box style={{ margin: '20px 0' }}>
                        <TextField
                            label="Search Applications"
                            variant="outlined"
                            fullWidth
                            value={searchTerm}
                            onChange={handleSearchChange}
                            style={{ marginBottom: '20px' }}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={featured} onChange={handleFeaturedChange} />}
                            label="Featured"
                            style={{ marginBottom: '20px' }}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={dockercompose} onChange={handleDockercomposeChange} />}
                            label="Docker Compose"
                            style={{ marginBottom: '20px' }}
                        />
                        <List>
                            <HighlightedListItem button onClick={() => handleCategoryClick('')}>
                                <ListItemText primary="All Categories" />
                            </HighlightedListItem>
                            {categories.map((category) => (
                                <ListItem button key={category.id} onClick={() => handleCategoryClick(category.id)}>
                                    <ListItemText primary={category.attributes.name} />
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                </Grid>
                <Grid item xs={9}>
                    <Grid container spacing={2} justifyContent="center">
                        {filteredApplications.map((application) => (
                            <Grid item key={application.id}>
                                <ApplicationCard application={application} />
                            </Grid>
                        ))}
                    </Grid>
                    <Box display="flex" justifyContent="center" marginTop="20px">
                        <Typography>Displayed items: {filteredApplications.length}</Typography>
                    </Box>
                    <Box display="flex" justifyContent="center" marginTop="20px">
                        <Typography>Total catalog items searched: {totalItems}</Typography>
                    </Box>
                    {!loading && !endOfList && (
                        <Box display="flex" justifyContent="center" marginTop="20px">
                            <Button variant="contained" onClick={loadMoreApplications}>
                                Load More ...
                            </Button>
                        </Box>
                    )}
                    {loading && (
                        <Box display="flex" justifyContent="center" marginTop="20px">
                            <Typography>Loading...</Typography>
                        </Box>
                    )}
                    {endOfList && (
                        <Box display="flex" justifyContent="center" marginTop="20px">
                            <Typography>End of catalog list.</Typography>
                        </Box>
                    )}
                </Grid>
            </Grid>
        </Container>
    );
};

export default ApplicationList;
