import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Accordion, AccordionSummary, AccordionDetails, List, ListItem, ListItemText, Paper, Container, Typography, Grid, Box, Button, useMediaQuery } from '@mui/material';
import axios from 'axios';
import '../App.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const BASE_URL = 'https://cms.mlops.io';

const apiToken = "e87846577d61104a5ed9647b8653f63a70dbd1ca05c5813ee8d484ec35db9ea48d1dc84d82e2ddfce33da81ddaded076cfec872e298a47c75bf57ce14379b5d65e5c1cc2b3b2af28f4e3a08cefd55bc1cf2f2c2009bfc88a2765adb0fbac82a311ab8edb82a716b3984f1f5a9018342971d5b589e3447f32f512d3e42b94724d";

const ApplicationDetail = () => {
    const { id } = useParams();
    const [application, setApplication] = useState(null);
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const [hovered, setHovered] = useState(false);
    const [hoverData, setHoverData] = useState(null);
    const [message, setMessage] = useState('');
    const [showButton, setShowButton] = useState(true);

    const formatDate = (isoDateString) => {
        const date = new Date(isoDateString);
        const options = {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZone: 'Australia/Sydney',
            timeZoneName: 'short'
        };
        const formatter = new Intl.DateTimeFormat('en-US', options);
        const parts = formatter.formatToParts(date);

        let formattedDate = '';

        parts.forEach(({ type, value }) => {
            if (type === 'day') {
                formattedDate += `${value}-`;
            } else if (type === 'month') {
                formattedDate += `${value.toUpperCase()}-`;
            } else if (type === 'year') {
                formattedDate += `${value} `;
            } else if (type === 'hour' || type === 'minute' || type === 'second') {
                formattedDate += `${value}`;
            } else if (type === 'literal') {
                if (value !== ',') {
                    formattedDate += value;
                }
            } else if (type === 'timeZoneName') {
                formattedDate += '(AEST)';
            }
        });

        return formattedDate.replace(',', '').replace('- ', '-');
    };

    useEffect(() => {

        setShowButton(true);

        const fetchApplication = async () => {
            try {
                const res = await axios.get(`${BASE_URL}/api/catalogs/${id}?populate=icon,images`, { headers: { 'Authorization': 'Bearer: ' + apiToken } });
                setApplication(res.data.data);
            } catch (error) {
                console.error('Error fetching application:', error);
            }
        };

        fetchApplication();
    }, [id]);

    const handleMouseEnter = async (fieldId) => {
        setHovered(true);
        try {
            try {
                const res = await axios.get(`${BASE_URL}/api/fields?filters[foreignId][$eq]=${application.attributes.catalogId}&filters[fieldId][$eq]=${fieldId}`, { headers: { 'Authorization': 'Bearer: ' + apiToken } });
                setHoverData(JSON.stringify(res.data.data[0]));
                if (res.data.data.length === 0) {
                    setMessage(`((${fieldId}) has quality score: -1 with note: "Not yet initialized"`);
                } else {
                    let typeId = 'catalog';
                    if (res.data.data[0].attributes.typeId) { typeId = res.data.data[0].attributes.typeId; }
                    setMessage(`(${typeId}[${res.data.data[0].attributes.foreignId}].${fieldId}) has quality score '${res.data.data[0].attributes.score}' of origin '${res.data.data[0].attributes.origin}' with note '${res.data.data[0].attributes.note}' and last updated on '${formatDate(res.data.data[0].attributes.updatedAt)}.`);
                }
                // alert(application.attributes.foreignId);

            } catch (error) {
                console.error('Error fetching application:', error);
            }
        } catch (error) {
            console.error("Error fetching data", error);
        }
    };

    const handleMouseLeave = () => {
        setHovered(false);
        setHoverData(null);
        setMessage("");
    };

    if (!application) {
        return <Typography variant="h6">Loading...</Typography>;
    }


    const JsonListDisplay = ({ title, data }) => {
        return (
            <Box m={2}>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h7" sx={{ fontWeight: 'regular' }}>
                            {title}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Paper elevation={3} style={{ padding: '10px', marginBottom: '5px', background: 'lightblue' }}>
                            {data ? (
                                <List sx={{ padding: 1 }}>
                                    {data.map((item, index) => (
                                        <ListItem
                                            key={index}
                                            alignItems="flex-start"
                                            sx={{
                                                padding: '1px 0',
                                                margin: '1px 0',
                                                margin: index === data.length - 1 ? '1px 0 0 0' : '2px 0', // No bottom margin for last item
                                            }}>
                                            <ListItemText
                                                primary={
                                                    <React.Fragment>
                                                        <Typography variant="body2" sx={{ fontSize: '0.875rem' }}>
                                                            {item}
                                                        </Typography>
                                                    </React.Fragment>
                                                }
                                            />
                                        </ListItem>
                                    ))}
                                </List>
                            ) : ""}
                        </Paper>
                    </AccordionDetails>
                </Accordion>
            </Box>
        );
    };

    const decodeBase64 = (encodedString) => {
        try {
            return atob(encodedString);
        } catch (error) {
            console.error('Error decoding Base64 string:', error);
            return 'Invalid Base64 content';
        }
    };

    const DecodedContentAccordion = ({ title, encodedContent }) => {
        const decodedContent = decodeBase64(encodedContent);
        return (
            <Box m={2}>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h7" sx={{ fontWeight: 'regular' }}>
                            {title}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Paper elevation={3} style={{ padding: '10px', marginBottom: '5px', background: 'lightgrey' }}>
                            <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap', fontSize: '0.875rem' }}>
                                {decodedContent}
                            </Typography>
                        </Paper>
                    </AccordionDetails>
                </Accordion>
            </Box>
        );
    };

    return (

        <Container style={{ marginTop: '40px', position: 'relative' }}>

            <div style={{ position: 'fixed', top: '5px', left: '200px', zIndex: 1000 }}>

                {showButton && (
                    <Button
                        variant="contained"
                        color="primary"
                        component={Link}
                        to="/"
                        className="back-to-list-button"
                    >
                        Back to List
                    </Button>
                )}
            </div>

            <Grid container spacing={2} alignItems="flex-start" direction={isMobile ? "column" : "row"}>
                <Grid item xs={12} sm={2}>
                    <Box display="flex" justifyContent="center" alignItems="center" textAlign="center" marginBottom={isMobile ? 2 : 0}>
                        {application.attributes.icon && (
                            <img
                                src={application.attributes.icon.data ? `${BASE_URL}${application.attributes.icon.data.attributes.url}` : `${BASE_URL}/uploads/catalog_default_63660645f0.png`}
                                alt={application.attributes.name}
                                style={{
                                    width: '100px',
                                    height: '100px',
                                    borderRadius: '50%',
                                    objectFit: 'cover',
                                }}
                            />
                        )}
                    </Box>
                </Grid>
                <Grid item xs={12} sm={10}>
                    <Box style={{ paddingLeft: isMobile ? '0' : '10px', textAlign: isMobile ? 'center' : 'left' }}>
                        <Typography variant="h4" gutterBottom>
                            {application.attributes.name}
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                            {application.attributes.tagline}
                        </Typography>
                        <div
                            onMouseEnter={() => handleMouseEnter("websiteUrl")}
                            onMouseLeave={handleMouseLeave}
                        >
                            <Typography variant="body1" gutterBottom>
                                WebSite: {application.attributes.websiteUrl}
                            </Typography>
                        </div>
                        <Typography variant="body1" gutterBottom>
                            {application.attributes.description}
                        </Typography>

                        <div
                            onMouseEnter={() => handleMouseEnter("version")}
                            onMouseLeave={handleMouseLeave}>
                            <Typography variant="body1" gutterBottom>
                                Version: {application.attributes.version}
                            </Typography>
                        </div>

                        <Typography variant="body1" gutterBottom>
                            Licenses: {application.attributes.licenses}
                        </Typography>

                        <div
                            onMouseEnter={() => handleMouseEnter("sourceUrl")}
                            onMouseLeave={handleMouseLeave}>
                            <Typography variant="body1" gutterBottom>
                                Source: {application.attributes.sourceUrl}
                            </Typography>
                        </div>

                        <div
                            onMouseEnter={() => handleMouseEnter("dockersiteUrl")}
                            onMouseLeave={handleMouseLeave}>
                            <Typography variant="body1" gutterBottom>
                                Docker WebSite: {application.attributes.dockersiteUrl}
                            </Typography>
                        </div>

                        <div
                            onMouseEnter={() => handleMouseEnter("dockercomposeUrl")}
                            onMouseLeave={handleMouseLeave}>
                            <Typography variant="body1" gutterBottom>
                                Docker Compose: {application.attributes.dockercomposeUrl}
                            </Typography>
                        </div>

                        <Typography variant="body1" gutterBottom>
                            Stars: {application.attributes.stargazerCount}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Forks: {application.attributes.forkCount}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Open Issues: {application.attributes.openIssueCount}
                            <Typography variant="body1" gutterBottom>
                                Featured: {application.attributes.isFeatured ? "Yes" : "No"}
                            </Typography>
                        </Typography>
                        <div>
                            <JsonListDisplay title="Product Features" data={application.attributes.product_features} />
                        </div>
                        <div>
                            <JsonListDisplay title="Platforms" data={application.attributes.platforms} />
                        </div>
                        <div>
                            <JsonListDisplay title="Hardware Requirements" data={application.attributes.hardware_requirements} />
                        </div>
                        <div>
                            <JsonListDisplay title="Setup Prerequisites" data={application.attributes.setup_prerequisites} />
                        </div>
                        <div>
                            <JsonListDisplay title="Languages" data={application.attributes.programming_languages} />
                        </div>
                        {application.attributes.dockercomposeBase64 && (
                            <DecodedContentAccordion title="AI Generated Docker Compose" encodedContent={application.attributes.dockercomposeBase64} />
                        )}
                        <Button variant="contained" color="primary" component={Link} to="/">
                            Back to List
                        </Button>
                    </Box>
                </Grid>
            </Grid>
            {message && <div className="bottom-message">{message}</div>}
        </Container >
    );
};

export default ApplicationDetail;
