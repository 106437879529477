import React from 'react';
// import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

// Define your Material-UI theme
const theme = createTheme();

//ReactDOM.render(
//  <BrowserRouter>
//    <App />
//  </BrowserRouter>,

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
