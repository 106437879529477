// src/components/PageNotFound.js
import React from 'react';

const PageNotFound = () => {
  return (
    <div>
      <h2>Page Not Found</h2>
      <p>Sorry, the page you are looking for does not exist.</p>
    </div>
  );
};

export default PageNotFound;
