// src/components/ApplicationCard.js
import React, { useEffect, useState } from 'react';
import { Card, CardContent, CardMedia, Typography, Box, appBarClasses } from '@mui/material';
import { Link } from 'react-router-dom';
import '../App.css';

const BASE_URL = 'https://cms.mlops.io';

const ApplicationCard = ({ application }) => {
    const imageUrl = application.attributes.icon.data ? `${BASE_URL}${application.attributes.icon.data.attributes.url}` : `${BASE_URL}/uploads/catalog_default_63660645f0.png`;
    const [hoveredField, setHoveredField] = useState(null);

    const handleMouseEnter = async (fieldId) => {
        setHoveredField(fieldId);
        // alert(fieldId);
    };

    const handleMouseLeave = () => {
        setHoveredField(null);
    };

    return (
        <Card style={{ width: '200px', height: '200px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', margin: '10px', backgroundColor: '#f5f5f5' }}>
            <Link to={`/application/${application.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <CardMedia
                        component="img"
                        style={{ marginTop: '30px', marginBottom: '0px', justifyContent: 'center', align: 'center', width: '60px', height: '60px' }} // Adjust size as needed
                        image={imageUrl} // Adjust based on how the icon URL is accessed
                        alt={application.attributes.name}
                    />
                    <CardContent style={{ textAlign: 'center' }}>
                        <Typography variant="h7" component="div" style={{ marginTop: '5px' }}>
                            {application.attributes.name}
                        </Typography>
                        <Box style={{ margin: '10px 0' }} /> {/* This box adds the gap */}

                        <div
                            onMouseEnter={() => handleMouseEnter("tagline")}
                            onMouseLeave={handleMouseLeave}
                            style={{ position: 'relative' }}
                        >
                            <Typography variant="body2" color="text.secondary" style={{ fontSize: '0.6rem', justifyContent: 'left', align: 'left' }}>
                                {application.attributes.tagline.length > 30 ? application.attributes.tagline.substr(0, 30) + ' ...' : application.attributes.tagline}
                            </Typography>
                            {hoveredField === "tagline" && (
                                <Typography variant="body2" style={{ borderRadius: '4px', zIndex: 1, padding: '5px', border: '1px solid #ccc', position: 'absolute', top: -40, background: 'white', fontSize: '0.8rem', justifyContent: 'left', align: 'left', justifyItem: 'left' }}>
                                    {application.attributes.tagline}
                                </Typography>
                            )}
                        </div>

                        <Typography variant="body3" color="text.secondary" style={{ marginTop: '5px', fontSize: '0.6rem', justifyContent: 'left', align: 'left' }}>
                            [{application.attributes.categoryId}]
                        </Typography>
                    </CardContent>
                </Box>
            </Link>
        </Card>
    );
};

export default ApplicationCard;
