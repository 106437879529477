import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
//import ApplicationList from './components/ApplicationList';
import ApplicationList from './components/ApplicationList';
import ApplicationDetail from './components/ApplicationDetail';
import PageNotFound from './components/PageNotFound';

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<ApplicationList />} />
        <Route path="/application/:id" element={<ApplicationDetail />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Router>
  );
}

export default App;

